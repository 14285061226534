import { template as template_3ddac84f9fe8432d9348a99aa1c3dd5a } from "@ember/template-compiler";
const SidebarSectionMessage = template_3ddac84f9fe8432d9348a99aa1c3dd5a(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
