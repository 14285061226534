import { template as template_910d066ffd574d73bfa7dab9d3c3b4da } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_910d066ffd574d73bfa7dab9d3c3b4da(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
