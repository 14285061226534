import { template as template_5d07eda8832e424e9514d56e855c975d } from "@ember/template-compiler";
const FKControlMenuContainer = template_5d07eda8832e424e9514d56e855c975d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
