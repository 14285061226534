import { template as template_672f781abd5247d8b4fe9e2769c31933 } from "@ember/template-compiler";
const WelcomeHeader = template_672f781abd5247d8b4fe9e2769c31933(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
